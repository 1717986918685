/*global gtag*/
import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Hero } from "@components/Hero"
import { Arrow } from "@components/UI"
import { AltAboutJk } from "@components/AboutJk"
import { Lottie } from "@components/Lottie"
import { Button, ButtonAction } from "@components/Button"
import { splitText } from "@utils"
import balanceLottie from "@lottie/balance_c.json"
import blockheartsLottie from "@lottie/blockhearts_c.json"
import lightbulbLottie from "@lottie/lightbulb_c.json"

const B2BBuyInGuidePage = ({ data }) => {
  const { employerBrandingDesktop, employerBrandingMobile, buyInGuideCover, aboutJkImage } = data

  const { themeColor } = usePageSetup({ themeColor: "bg-blue-light", displayGeneralForm: false })

  const downloadGuide = () => {
    const isBrowser = typeof window !== "undefined"
    if (!isBrowser) {
      return
    }
    gtag('event', 'ungated_download', {'event_category': 'download','event_label': 'b2b_buy_in_guide'})
    window.open("/documents/jk-b2b-branding-guide-2022.pdf", "_blank").focus()
  }

  const talkToExpert = () => {
    const isBrowser = typeof window !== "undefined"
    if (!isBrowser) {
      return
    }
    gtag('event', 'talk_to_b2b_expert', {'event_category': 'contact_us','event_label': 'jk_expert_contact_submit'})
  }

  return (
    <Layout>
      <Seo title="B2B Branding Guide" description="" />
      <section className={`${themeColor} overflow-hidden`}>
        <Hero className="pb-clamp-16-30 pt-clamp-16-26">
          <Hero.Title>
            <div className="lg:grid grid-cols-10">
              <div className="col-span-9">
                <h1
                  className="text-clamp-40-70"
                  data-aos="stagger"
                  dangerouslySetInnerHTML={{
                    __html: splitText({
                      "text-white": "Free guide:",
                      "text-gray-darkest": "Why your company needs to rebrand (or doesn’t).",
                    }),
                  }}
                />
              </div>
            </div>
          </Hero.Title>
        </Hero>
      </section>

      <section className="overflow-hidden relative pb-clamp-16-40">
        <div className="absolute top-32 bottom-0 inset-x-0 hidden lg:block">
          <div className="container">
            <div className="grid grid-cols-12 gap-x-4">
              <div className="col-span-4 flex">
                <div className="bg-teal-light extend before:bg-teal-light w-full aspect-w-468 aspect-h-525"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-blue-light">
          <div className="relative container">
            <div className="relative w-full lg:w-10/12 mx-auto">
              <div className="lg:grid grid-cols-10 gap-x-4">
                <div className="relative col-span-4">
                  <div className="w-full lg:aspect-w-468 lg:aspect-h-490 relative">
                    <div className="lg:absolute inset-0 filter drop-shadow-lg">
                      <GatsbyImage image={getImage(buyInGuideCover)} alt="Buy-in Guide" />
                    </div>
                  </div>
                </div>
                <div className="col-span-6 lg:px-10 pb-40">
                  <div className="mt-12">
                    <h3 className="text-22">Four good reasons to update your B2B brand… and four good reasons to leave it alone.</h3>
                    <p className="mt-8">
                      Your B2B brand might require a total overhaul—or just an infusion of fresh ideas to help drive engagement, conversions, and loyalty. How do you know what to do?
                    </p>
                    <div className="mt-10">
                      <ButtonAction func={downloadGuide} className="bg-gray-darkest text-white p-5">
                        Download the free guide
                      </ButtonAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
        </div>
      </section>



      <main className="overflow-hidden">

        <section className="mt-clamp-8-16">
          <div className="container">
            <div className="flex lg:justify-center">
              <div className="lg:w-10/12">
                <div>
                  <h2 className="text-clamp-26-32">This free B2B branding guide will help you to:</h2>
                </div>
                <div className="grid lg:grid-cols-2 lg:grid-cols-10 gap-x-4 gap-y-8 md:gap-y-16 mt-16">
                  <div data-aos="fade-up" className="lg:col-span-3 sm:flex items-center lg:flex-col">
                    <div className="mr-auto w-full flex" style={{ height: "112px", maxWidth: "175px" }}>
                      <Lottie animation={balanceLottie} width="94px" />
                    </div>
                    <div>
                      <p className="mt-10 sm:mt-0 lg:mt-8">Determine if you really need to rebrand.</p>
                    </div>
                  </div>
                  <div data-aos="fade-up" data-aos-delay="200" className="lg:col-span-4 flex flex-col">
                    <div className="lg:w-3/4 lg:mx-auto sm:flex items-center lg:flex-col">
                      <div className="mr-auto w-full flex" style={{ height: "112px", maxWidth: "175px" }}>
                        <Lottie animation={blockheartsLottie} width="125px" />
                      </div>
                      <div>
                        <p className="mt-10 sm:mt-0 lg:mt-8">Understand when rebranding is likely the wrong answer.</p>
                      </div>
                    </div>
                  </div>
                  <div data-aos="fade-up" data-aos-delay="400" className="lg:col-span-3 gap-x-6 sm:flex items-center lg:flex-col">
                    <div className="mr-auto lg:w-full flex" style={{ height: "112px", maxWidth: "175px" }}>
                      <Lottie animation={lightbulbLottie} width="147px" />
                    </div>
                    <div>
                      <p className="mt-10 sm:mt-0 lg:mt-8">Explore non-branding options to revitalize your B2B story.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-clamp-33-49">
          <div className="flex relative lg:hidden">
            <div className="absolute inset-0">
              <div className="container flex h-full">
                <div className="mt-auto bg-blue-light extend after:bg-blue-light w-full aspect-w-326 aspect-h-144"></div>
              </div>
            </div>
            <div data-aos="fade-right" className="w-3/4">
              <GatsbyImage image={getImage(employerBrandingMobile)} alt="Interested in B2B branding?" />
            </div>
          </div>
          <div className="container lg:grid grid-cols-12">
            <div className="relative hidden lg:block col-start-1 col-end-6 relative z-10">
              <div className="absolute inset-0 flex">
                <div data-aos="fade-right" className="absolute right-0">
                  <GatsbyImage image={getImage(employerBrandingDesktop)} alt="Interested in B2B branding?" />
                </div>
              </div>
            </div>
            <div className="relative bg-blue-light lg:grid col-start-2 col-end-13 grid-cols-11 lg:mt-24 py-clamp-18-38">
              <div className="absolute inset-0">
                <div className="w-full h-full bg-blue-light extend before:bg-blue-light lg:before:bg-transparent after:bg-blue-light"></div>
              </div>
              <div className="relative col-start-5 col-end-11 grid grid-cols-6">
                <div className="col-span-6 lg:pl-20">
                  <h2
                    data-aos="stagger"
                    className="text-title"
                    dangerouslySetInnerHTML={{
                      __html: splitText({
                        "text-white": "Interested in",
                        break: "",
                        "text-gray-darkest": "B2B branding?",
                      }),
                    }}
                  />
                  <div data-aos="fade-left" className="flex mt-10">
                    <Button link="/contact" func={talkToExpert} className="px-9 py-5 bg-gray-darkest text-left text-white sm:hover:text-white sm:hover:underline lg:ml-auto">
                      Talk to a JK expert
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
        <section className="pt-clamp-44-62">
          <AltAboutJk>
            <AltAboutJk.Content>
              JK is a creative marketing agency and women-owned business that works to truly understand your business, your customers, and your competition—so we can tell your best, most authentic B2B brand story, one that reflects who you truly are and the unique value you create. Backed by over 30 years of B2B branding experience and a proven, insight-driven process, we’re ready to build a brand that builds your business.
            </AltAboutJk.Content>
            <div data-aos="fade-left" className="relative flex pb-16">
              <div className="absolute inset-0 flex">
                <div className="ml-auto mt-auto w-10/12 md:w-4/6">
                  <div className="aspect-w-468 aspect-h-385 bg-teal-light extend after:bg-teal-light md:extend-none"></div>
                </div>
              </div>
              <div className="flex md:mr-auto md:w-10/12">
                <GatsbyImage objectPosition="top" objectFit="cover" image={getImage(aboutJkImage)} alt="About JK" />
              </div>
            </div>
          </AltAboutJk>
        </section>
      </main>
    </Layout>
  )
}

export default B2BBuyInGuidePage

export const B2BBuyInGuideQuery = graphql`
  query b2BBuyInGuideQuery {
    employerBrandingDesktop: file(relativePath: { eq: "b2b/jk-expert.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 830)
      }
    }
    employerBrandingMobile: file(relativePath: { eq: "b2b/jk-expert.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    buyInGuideCover: file(relativePath: { eq: "b2b/buy-in-guide-cover.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    aboutJkImage: file(relativePath: { eq: "b2b/about-jk.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
